var render = function () {
  var _vm$newReportFormData, _vm$newReportFormData2, _vm$newReportFormData3, _vm$newReportFormData4, _vm$newReportFormData5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "reportForm",
    class: ['report-form', _vm.isLoading ? 'is-loading' : '']
  }, [_c('div', {
    staticClass: "report-form__loader"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  })], 1), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-1 offset-11"
  }, [_c('button', {
    staticClass: "report-form__close-btn",
    on: {
      "click": _vm.handleCloseBtn
    }
  })])])]), _c('keep-alive', [_c(_vm.activeStepComponent, {
    tag: "component",
    attrs: {
      "reports": _vm.reports,
      "lastCompletedReport": _vm.lastCompletedReport,
      "groupedReports": _vm.groupedReports,
      "isDisabled": _vm.isDisabled,
      "reportStatus": _vm.reportStatus,
      "reportId": _vm.reportId
    },
    on: {
      "setNextStepBtnDisabled": _vm.setNextStepBtnDisabled,
      "updateTotals": _vm.updateTotals,
      "updateBalanceAssets": _vm.updateBalanceAssets,
      "updateBalanceLiabilities": _vm.updateBalanceLiabilities,
      "updateBalanceAdditionals": _vm.updateBalanceAdditionals,
      "updateFiles": function updateFiles($event) {
        _vm.reRenderActions++;
      },
      "setLoading": function setLoading($event) {
        return _vm.setLoading($event);
      }
    }
  })], 1), _c('div', {
    key: "report-actions-".concat(_vm.reRenderActions),
    staticClass: "container actions"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "report-form__actions"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled black add-new-report",
    attrs: {
      "disabled": _vm.newReportFormData.currentStep === 1
    },
    on: {
      "click": _vm.previousStep
    }
  }, [_vm._v(" Previous step ")]), _vm.showDraftBtn ? _c('sygni-rounded-button', {
    staticClass: "add-new-draft outline black",
    on: {
      "click": _vm.saveDraft
    }
  }, [_vm._v("Save draft")]) : _vm._e(), _vm.showUpdateReportBtn && (((_vm$newReportFormData = _vm.newReportFormData) === null || _vm$newReportFormData === void 0 ? void 0 : _vm$newReportFormData.currentStep) === 4 || ((_vm$newReportFormData2 = _vm.newReportFormData) === null || _vm$newReportFormData2 === void 0 ? void 0 : _vm$newReportFormData2.currentStep) === 5 || ((_vm$newReportFormData3 = _vm.newReportFormData) === null || _vm$newReportFormData3 === void 0 ? void 0 : _vm$newReportFormData3.currentStep) === 6) ? _c('sygni-rounded-button', {
    staticClass: "update-submitted-report outline black",
    on: {
      "click": _vm.submitReport
    }
  }, [_vm._v("Update report")]) : _vm._e(), ((_vm$newReportFormData4 = _vm.newReportFormData) === null || _vm$newReportFormData4 === void 0 ? void 0 : _vm$newReportFormData4.currentStep) !== 4 ? _c('sygni-rounded-button', {
    staticClass: "filled black add-new-report",
    attrs: {
      "disabled": false,
      "tooltipCustomClass": "tooltip-error"
    },
    on: {
      "click": _vm.nextStep
    }
  }, [_vm._v(" " + _vm._s(_vm.nextBtnText) + " ")]) : _vm._e(), ((_vm$newReportFormData5 = _vm.newReportFormData) === null || _vm$newReportFormData5 === void 0 ? void 0 : _vm$newReportFormData5.currentStep) === 4 && !_vm.isDisabled ? _c('sygni-rounded-button', {
    key: "report-next-btn".concat(_vm.reRenderActions),
    staticClass: "filled black add-new-report",
    attrs: {
      "disabled": _vm.canGenerate ? _vm.btnNextStepDisabled : false,
      "loading": _vm.isLoading
    },
    on: {
      "click": _vm.nextStep
    }
  }, [_vm._v(" " + _vm._s(_vm.nextBtnText) + " ")]) : _vm._e()], 1)])])]), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showSubmitReportModalConfirmation,
      expression: "showSubmitReportModalConfirmation"
    }],
    attrs: {
      "cancelText": "Nie, wróc do raportu",
      "confirmText": "Tak, chcę to zrobić",
      "isLoading": _vm.isSubmitReportModalLoading,
      "header": "Zatwierdzanie danych",
      "description": "Czy na pewno chcesz ".concat(_vm.reportStatus === _vm.ReportStatuses.APPROVED ? 'zaktualizować' : 'zatwierdzić', " raport, bez wprowadzonych danych bilansowych?")
    },
    on: {
      "close": _vm.closeSubmitReportModal,
      "cancel": _vm.closeSubmitReportModal,
      "confirm": _vm.approveReport
    }
  }), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.infoModal.show,
      expression: "infoModal.show"
    }],
    attrs: {
      "cancelText": "",
      "confirmText": "OK",
      "header": _vm.infoModal.title,
      "description": _vm.infoModal.description
    },
    on: {
      "confirm": function confirm($event) {
        _vm.infoModal.show = false;
      },
      "close": function close($event) {
        _vm.infoModal.show = false;
      }
    }
  }), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.draftModal.show,
      expression: "draftModal.show"
    }],
    attrs: {
      "cancelText": "Quit without saving",
      "confirmText": "Save draft",
      "header": _vm.draftModal.title,
      "description": _vm.draftModal.description
    },
    on: {
      "confirm": _vm.closeDraftModalAndSave,
      "close": _vm.closeDraftModal,
      "cancel": function cancel($event) {
        return _vm.closeDraftModal(_vm.nextRoute, true);
      }
    }
  }), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showValidationErrorModal,
      expression: "showValidationErrorModal"
    }],
    attrs: {
      "cancelText": "",
      "confirmText": "OK",
      "header": "Wystąpił błąd",
      "description": _vm.validationErrorMessage
    },
    on: {
      "confirm": _vm.closeValidationErrorModal,
      "close": _vm.closeValidationErrorModal
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }