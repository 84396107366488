var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reporting-regulatory-card"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Regulatory reporting for " + _vm._s(_vm.report.year) + " "), _c('span', {
    staticClass: "grey"
  }, [_vm._v("(KNF reporting)")])]), _c('div', {
    staticClass: "toggle-show",
    on: {
      "click": function click($event) {
        return _vm.toggleOpen();
      }
    }
  }, [_vm.isOpen ? [_vm._v(" Hide "), _c('img', {
    staticClass: "arrow-down",
    attrs: {
      "src": require("@/assets/icons/arrow.svg")
    }
  })] : _vm._e(), !_vm.isOpen ? [_vm._v(" Show "), _c('img', {
    attrs: {
      "src": require("@/assets/icons/arrow.svg")
    }
  })] : _vm._e()], 2)]), !_vm.isLoadingReport ? [_vm.report.status === _vm.reportStatuses.GENERATED ? _c('reg-rep-creating-raport') : _vm._e(), _vm.report.status === _vm.reportStatuses.COMPLETED ? _vm._l(_vm.report.outputFiles, function (outputFile) {
    return _c('sygni-card', {
      key: outputFile.id,
      staticClass: "final-document",
      class: _vm.isOpen
    }, [_c('div', {
      staticClass: "final-document__col final-document__col--name"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/icons/docs--red.svg")
      }
    }), _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: outputFile.fileName,
        expression: "outputFile.fileName",
        modifiers: {
          "hover": true
        }
      }]
    }, [_vm._v(_vm._s(outputFile.fileName))])]), _vm.isXmlReport(outputFile) ? _c('router-link', {
      staticClass: "final-document__col final-document__col--icon",
      attrs: {
        "to": {
          name: 'fund-reg-xml-preview',
          query: {
            id: outputFile.fileId
          }
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/icons/fund.svg")
      }
    })]) : _vm._e(), _c('div', {
      staticClass: "final-document__col final-document__col--icon"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/icons/download.svg")
      },
      on: {
        "click": function click($event) {
          return _vm.downloadFile(outputFile);
        }
      }
    })])], 1);
  }) : _vm._e(), _vm.isOpen ? _c('div', {
    staticClass: "funds"
  }, [_vm._l(_vm.report.funds, function (fund) {
    return [_c('sygni-card', {
      key: fund.id,
      staticClass: "inner-card sygni-card--borderless",
      class: _vm.report.status === _vm.reportStatuses.COMPLETED || _vm.report.status === _vm.reportStatuses.GENERATED ? 'inner-card--confirmed' : ''
    }, [_c('div', {
      staticClass: "inner-card__row inner-card__row--header"
    }, [_c('div', {
      staticClass: "image"
    }, [fund.logo ? _c('img', {
      attrs: {
        "src": fund.logo
      }
    }) : _c('div', {
      staticClass: "char"
    }, [_vm._v(" " + _vm._s(fund.name.slice(0, 1)) + " ")])]), _vm._v(" " + _vm._s(fund.name) + " ")]), _vm.report.status === _vm.reportStatuses.NEW ? _c('file-uploader', {
      attrs: {
        "supported-file-formats": _vm.supportedFileFormats,
        "uploadProgress": _vm.uploadProgress
      },
      on: {
        "input": function input($event) {
          return _vm.addFile($event, fund);
        }
      }
    }) : _vm._e(), _vm.report.status === _vm.reportStatuses.NEW ? _c('sygni-container-title', {
      staticClass: "uploaded-files-title"
    }, [_vm._v(" Uploaded files ")]) : _vm._e(), _c('reporting-regulatory-list', {
      attrs: {
        "confirmed": _vm.report.status === _vm.reportStatuses.COMPLETED || _vm.report.status === _vm.reportStatuses.GENERATED,
        "items": fund.inputFiles,
        "report": _vm.report,
        "showPagination": false
      }
    })], 1)];
  }), _vm.report.status === _vm.reportStatuses.NEW ? _c('sygni-rounded-button', {
    staticClass: "red filled create-report-button",
    attrs: {
      "disabled": !_vm.isReportReadyToCreate,
      "loading": _vm.reportGeneratingLoading,
      "plus-icon": true
    },
    on: {
      "click": _vm.createReport
    }
  }, [_vm._v(" Create report ")]) : _vm._e()], 2) : _vm._e(), !_vm.isOpen && _vm.report.status === _vm.reportStatuses.NEW ? _c('reg-rep-hidden-card') : _vm._e()] : _vm._e(), _vm.isLoadingReport ? [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }