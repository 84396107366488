var render = function () {
  var _vm$selectedReport, _vm$selectedReport2, _vm$selectedReport3, _vm$selectedReport4, _vm$selectedReport5, _vm$selectedReport6, _vm$selectedReport7, _vm$selectedReport8, _vm$selectedReport9, _vm$selectedReport10, _vm$selectedReport11, _vm$selectedReport12, _vm$selectedReport13, _vm$selectedReport23, _vm$selectedReport24;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reporting-regulatory-card"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Regulatory reporting for " + _vm._s((_vm$selectedReport = _vm.selectedReport) === null || _vm$selectedReport === void 0 ? void 0 : _vm$selectedReport.year) + " "), _c('span', {
    staticClass: "grey"
  }, [_vm._v("(KNF reporting)")])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ((_vm$selectedReport2 = _vm.selectedReport) === null || _vm$selectedReport2 === void 0 ? void 0 : _vm$selectedReport2.status) !== _vm.reportStatuses.DRAFT,
      expression: "selectedReport?.status !== reportStatuses.DRAFT"
    }],
    staticClass: "toggle-show",
    on: {
      "click": function click($event) {
        return _vm.toggleOpen();
      }
    }
  }, [_vm.isOpen ? [_c('span', [_vm._v("Hide details")]), _c('img', {
    staticClass: "arrow-down",
    attrs: {
      "src": require("@/assets/icons/arrow.svg")
    }
  })] : _vm._e(), !_vm.isOpen ? [_c('span', [_vm._v("Show details")]), _c('img', {
    attrs: {
      "src": require("@/assets/icons/arrow.svg")
    }
  })] : _vm._e()], 2), _vm.reportOptions.length > 1 ? _c('div', {
    staticClass: "reporting-regulatory-card__version"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.reportOptions,
      "label": "",
      "allow-empty": false
    },
    on: {
      "input": _vm.closeOpen
    },
    model: {
      value: _vm.selectedVersion,
      callback: function callback($$v) {
        _vm.selectedVersion = $$v;
      },
      expression: "selectedVersion"
    }
  })], 1) : _vm._e()]), !_vm.isLoadingReport ? [((_vm$selectedReport3 = _vm.selectedReport) === null || _vm$selectedReport3 === void 0 ? void 0 : _vm$selectedReport3.status) === _vm.reportStatuses.DRAFT ? [_c('sygni-card', {
    staticClass: "inner-card sygni-card--borderless"
  }, [_c('div', {
    staticClass: "reg-rep-draft-report"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/docs.svg")
    }
  }), _c('span', [_vm._v(" You have a draft report created already. "), _c('a', {
    staticClass: "red",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.editDraftReport.apply(null, arguments);
      }
    }
  }, [_vm._v("Edit it here")])])])])] : _vm._e(), ((_vm$selectedReport4 = _vm.selectedReport) === null || _vm$selectedReport4 === void 0 ? void 0 : _vm$selectedReport4.status) === _vm.reportStatuses.GENERATED || !_vm.isAdmin && ((_vm$selectedReport5 = _vm.selectedReport) === null || _vm$selectedReport5 === void 0 ? void 0 : _vm$selectedReport5.status) === _vm.reportStatuses.APPROVED ? _c('reg-rep-creating-raport') : _vm._e(), (((_vm$selectedReport6 = _vm.selectedReport) === null || _vm$selectedReport6 === void 0 ? void 0 : _vm$selectedReport6.status) === _vm.reportStatuses.GENERATED || ((_vm$selectedReport7 = _vm.selectedReport) === null || _vm$selectedReport7 === void 0 ? void 0 : _vm$selectedReport7.status) === _vm.reportStatuses.COMPLETED) && (_vm$selectedReport8 = _vm.selectedReport) !== null && _vm$selectedReport8 !== void 0 && _vm$selectedReport8.data || ((_vm$selectedReport9 = _vm.selectedReport) === null || _vm$selectedReport9 === void 0 ? void 0 : _vm$selectedReport9.status) === _vm.reportStatuses.APPROVED ? [_c('sygni-card', {
    staticClass: "final-document mt-4"
  }, [_c('div', {
    staticClass: "final-document__col final-document__col--name"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/docs--red.svg")
    }
  }), _c('span', [_vm._v("Report form")])]), _c('div', {
    staticClass: "final-document__col final-document__col--icon",
    on: {
      "click": function click($event) {
        return _vm.$emit('openReportPreview', _vm.selectedReport);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/fund.svg"),
      "alt": "Preview"
    }
  })])])] : _vm._e(), ((_vm$selectedReport10 = _vm.selectedReport) === null || _vm$selectedReport10 === void 0 ? void 0 : _vm$selectedReport10.status) === _vm.reportStatuses.COMPLETED ? _vm._l((_vm$selectedReport11 = _vm.selectedReport) === null || _vm$selectedReport11 === void 0 ? void 0 : _vm$selectedReport11.outputFiles, function (outputFile) {
    return _c('sygni-card', {
      key: outputFile.id,
      staticClass: "final-document",
      class: _vm.isOpen
    }, [_c('div', {
      staticClass: "final-document__col final-document__col--name"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/icons/docs--red.svg")
      }
    }), _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: outputFile.fileName,
        expression: "outputFile.fileName",
        modifiers: {
          "hover": true
        }
      }]
    }, [_vm._v(_vm._s(outputFile.fileName))])]), _vm.isXmlReport(outputFile) ? _c('router-link', {
      staticClass: "final-document__col final-document__col--icon",
      attrs: {
        "to": {
          name: 'fund-reg-xml-preview',
          query: {
            id: outputFile.fileId
          }
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/icons/fund.svg")
      }
    })]) : _vm._e(), _c('div', {
      staticClass: "final-document__col final-document__col--icon"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/icons/download.svg")
      },
      on: {
        "click": function click($event) {
          return _vm.downloadFile(outputFile);
        }
      }
    })])], 1);
  }) : _vm._e(), _vm.isOpen && ((_vm$selectedReport12 = _vm.selectedReport) === null || _vm$selectedReport12 === void 0 ? void 0 : _vm$selectedReport12.status) !== _vm.reportStatuses.DRAFT ? _c('div', {
    staticClass: "funds"
  }, [_vm._l((_vm$selectedReport13 = _vm.selectedReport) === null || _vm$selectedReport13 === void 0 ? void 0 : _vm$selectedReport13.funds, function (fund) {
    var _vm$selectedReport14, _vm$selectedReport15, _vm$selectedReport16, _vm$selectedReport17, _vm$selectedReport18, _vm$selectedReport19, _vm$selectedReport20, _vm$selectedReport21, _vm$selectedReport22;

    return [_c('sygni-card', {
      key: fund.id,
      staticClass: "inner-card sygni-card--borderless",
      class: ((_vm$selectedReport14 = _vm.selectedReport) === null || _vm$selectedReport14 === void 0 ? void 0 : _vm$selectedReport14.status) === _vm.reportStatuses.COMPLETED || ((_vm$selectedReport15 = _vm.selectedReport) === null || _vm$selectedReport15 === void 0 ? void 0 : _vm$selectedReport15.status) === _vm.reportStatuses.GENERATED || ((_vm$selectedReport16 = _vm.selectedReport) === null || _vm$selectedReport16 === void 0 ? void 0 : _vm$selectedReport16.status) === _vm.reportStatuses.APPROVED ? 'inner-card--confirmed' : ''
    }, [((_vm$selectedReport17 = _vm.selectedReport) === null || _vm$selectedReport17 === void 0 ? void 0 : _vm$selectedReport17.status) === _vm.reportStatuses.NEW ? _c('sygni-info-box', [_c('p', [_vm._v("W celu weryfikacji poprawności wprowadzonych danych przez naszych ekspertów, prosimy o dodanie informacji (Bilans, ZOiS), na podstawie których uzupełniony został formularz z danymi oraz przypisanie kategorii zestawienia.")])]) : _vm._e(), _c('div', {
      staticClass: "inner-card__row inner-card__row--header"
    }, [_c('div', {
      staticClass: "image"
    }, [fund.logo ? _c('img', {
      attrs: {
        "src": fund.logo
      }
    }) : _c('div', {
      staticClass: "char"
    }, [_vm._v(" " + _vm._s(fund.name.slice(0, 1)) + " ")])]), _vm._v(" " + _vm._s(fund.name) + " ")]), ((_vm$selectedReport18 = _vm.selectedReport) === null || _vm$selectedReport18 === void 0 ? void 0 : _vm$selectedReport18.status) === _vm.reportStatuses.NEW ? _c('file-uploader', {
      attrs: {
        "supported-file-formats": _vm.supportedFileFormats,
        "uploadProgress": _vm.uploadProgress
      },
      on: {
        "input": function input($event) {
          return _vm.addFile($event, fund);
        }
      }
    }) : _vm._e(), ((_vm$selectedReport19 = _vm.selectedReport) === null || _vm$selectedReport19 === void 0 ? void 0 : _vm$selectedReport19.status) === _vm.reportStatuses.NEW ? _c('sygni-container-title', {
      staticClass: "uploaded-files-title"
    }, [_vm._v(" Uploaded files ")]) : _vm._e(), _c('reporting-regulatory-list', {
      attrs: {
        "confirmed": ((_vm$selectedReport20 = _vm.selectedReport) === null || _vm$selectedReport20 === void 0 ? void 0 : _vm$selectedReport20.status) === _vm.reportStatuses.COMPLETED || ((_vm$selectedReport21 = _vm.selectedReport) === null || _vm$selectedReport21 === void 0 ? void 0 : _vm$selectedReport21.status) === _vm.reportStatuses.GENERATED || ((_vm$selectedReport22 = _vm.selectedReport) === null || _vm$selectedReport22 === void 0 ? void 0 : _vm$selectedReport22.status) === _vm.reportStatuses.APPROVED,
        "items": fund.inputFiles,
        "report": _vm.selectedReport,
        "showPagination": false
      }
    })], 1)];
  }), ((_vm$selectedReport23 = _vm.selectedReport) === null || _vm$selectedReport23 === void 0 ? void 0 : _vm$selectedReport23.status) === _vm.reportStatuses.NEW ? _c('sygni-rounded-button', {
    staticClass: "red filled create-report-button",
    attrs: {
      "disabled": !_vm.isReportReadyToCreate,
      "loading": _vm.reportGeneratingLoading,
      "plus-icon": true
    },
    on: {
      "click": _vm.createReport
    }
  }, [_vm._v(" " + _vm._s(_vm.isAdmin ? 'Create report' : 'Submit report') + " ")]) : _vm._e()], 2) : _vm._e(), !_vm.isOpen && ((_vm$selectedReport24 = _vm.selectedReport) === null || _vm$selectedReport24 === void 0 ? void 0 : _vm$selectedReport24.status) === _vm.reportStatuses.NEW ? _c('reg-rep-hidden-card') : _vm._e()] : _vm._e(), _vm.isLoadingReport ? [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }