var render = function () {
  var _vm$selectedASI, _vm$selectedASI2, _vm$selectedASI3, _vm$selectedASI4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "step"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-10 offset-lg-1"
  }, [_c('sygni-container-title', {
    staticClass: "step__title"
  }, [_vm._v(_vm._s(!_vm.isDetailsStep ? 'Krok 5 - Uzupełnij dane bilansowe' : 'Krok 6 - Rozbij portfel inwestycyjny'))]), _c('sygni-info-box', [!_vm.isDetailsStep ? [_c('p', [_vm._v("Podaj niezbędne dane dla aktywów oraz kapitałów funduszu. W dodatkowych informacjach podaj łączną sumę kapitału wniesionego przez 5 największych inwestorów. W przypadku mniejszej liczby inwestorów podaj sumę kapitału podstawowego.")])] : [_c('p', [_vm._v("Rozbij portfel na poszczególne instrumenty finansowe podając ich nazwę (np. Pożyczka XXX sp. z o.o.), rynek oraz wartość. W przypadku rynku giełdowego bądź NewConnect, uzupełnij kod ISIN korzystając z linków: "), _c('a', {
    attrs: {
      "href": "https://newconnect.pl/spolki",
      "target": "_blank"
    }
  }, [_vm._v("https://newconnect.pl/spolki")]), _vm._v(" - dla NewConnect, "), _c('a', {
    attrs: {
      "href": "https://www.gpw.pl/spolki",
      "target": "_blank"
    }
  }, [_vm._v("https://www.gpw.pl/spolki")]), _vm._v(" - dla Rynku giełdowego")])]], 2), _vm.isDetailsStep ? _c('div', {
    staticClass: "detail-boxes"
  }, [_c('div', {
    staticClass: "detail-boxes__name"
  }, [_vm._v(_vm._s("".concat((_vm$selectedASI = _vm.selectedASI) === null || _vm$selectedASI === void 0 ? void 0 : _vm$selectedASI.name, " (").concat((_vm$selectedASI2 = _vm.selectedASI) === null || _vm$selectedASI2 === void 0 ? void 0 : _vm$selectedASI2.aifNumber, ")")))]), _vm._l(_vm.filteredTableAssets, function (asset, i) {
    var _vm$assetTypeOptionsE;

    return _c('div', {
      key: "".concat(asset === null || asset === void 0 ? void 0 : asset.code, "-").concat(i),
      staticClass: "details-box"
    }, [_c('div', {
      staticClass: "details-box__header"
    }, [_vm._m(0, true), _c('div', {
      staticClass: "details-box__header-group"
    }, [_c('p', {
      staticClass: "title mr-4"
    }, [_vm._v(_vm._s(_vm.getAssetTitle(asset === null || asset === void 0 ? void 0 : asset.code)))]), _c('p', {
      staticClass: "title text-nowrap"
    }, [_vm._v(_vm._s(_vm._f("numberFormat")((asset === null || asset === void 0 ? void 0 : asset.amount) || 0)) + " "), _c('span', {
      staticClass: "ml-4"
    }, [_vm._v("PLN")])])])]), _c('div', {
      staticClass: "details-box__container"
    }, [_c('div', {
      staticClass: "flex-row"
    }, [_vm._m(1, true), _c('div', {
      staticClass: "action-buttons"
    }, [_c('sygni-link-button', {
      staticClass: "black text-black copy-button",
      attrs: {
        "type": "simple"
      },
      on: {
        "click": function click($event) {
          return _vm.copyDetails(asset);
        }
      }
    }, [_vm._v("Skopiuj portfel"), _c('img', {
      attrs: {
        "src": _vm._f("getIcon")('DOCS'),
        "alt": "Copy"
      }
    })]), !_vm.isDisabled && _vm.shouldImportButtonBeVisible(asset === null || asset === void 0 ? void 0 : asset.code) ? _c('sygni-link-button', {
      staticClass: "black text-black import-button mt-3",
      attrs: {
        "type": "simple"
      },
      on: {
        "click": function click($event) {
          return _vm.importDetailsFromPreviousYear(asset);
        }
      }
    }, [_vm._v("Wczytaj aktywa z zeszłego roku "), _c('img', {
      attrs: {
        "src": _vm._f("getIcon")('UPLOAD'),
        "alt": "Import"
      }
    })]) : _vm._e()], 1)]), _c('div', {
      staticClass: "details-box__options"
    }, [_c('div', {
      staticClass: "details-box__option"
    }, [_c('table', {
      staticClass: "details-box__option-table"
    }, [_c('thead', [_c('tr', [_c('th', {
      staticClass: "label text-left"
    }, [_vm._v("NAZWA AKTYWÓW")]), _c('th', {
      staticClass: "label text-center"
    }, [_vm._v("WARTOŚĆ")]), _c('th', {
      staticClass: "label text-center"
    }, [_vm._v("REGION")]), (_vm$assetTypeOptionsE = _vm.assetTypeOptionsEnabledFor) !== null && _vm$assetTypeOptionsE !== void 0 && _vm$assetTypeOptionsE.includes(asset === null || asset === void 0 ? void 0 : asset.code) ? _c('th', {
      staticClass: "label text-center"
    }, [_vm._v("RODZAJ")]) : _vm._e(), _c('th', {
      staticClass: "label text-center"
    }, [_vm._v("RYNEK")]), _c('th', {
      staticClass: "label text-center"
    }, [_vm._v("KOD ISIN")]), _c('th', {
      staticClass: "label text-right"
    }, [_vm._v("ZARZĄDZAJ")])])]), _c('tbody', _vm._l(asset === null || asset === void 0 ? void 0 : asset.details, function (item, index) {
      var _vm$assetTypeOptionsE2, _asset$details;

      return _c('tr', {
        key: "".concat(item.id, "-").concat(_vm.reRenderDetails, "-new")
      }, [_c('td', [_c('div', {
        staticClass: "cell left"
      }, [_c('div', {
        staticClass: "flex-row"
      }, [_c('span', {
        staticClass: "counter"
      }, [_vm._v(_vm._s(index + 1) + ".")]), _c('sygni-input', {
        attrs: {
          "nativeTooltip": item.name,
          "displayFormat": 'toPhrase',
          "disabled": _vm.isDisabled
        },
        on: {
          "paste": function paste($event) {
            $event.stopPropagation();
            return _vm.pasteHandler($event, 'name', asset, index);
          }
        },
        model: {
          value: item.name,
          callback: function callback($$v) {
            _vm.$set(item, "name", $$v);
          },
          expression: "item.name"
        }
      })], 1)])]), _c('td', [_c('div', {
        staticClass: "cell right"
      }, [_c('sygni-input', {
        attrs: {
          "displayFormat": "number",
          "nativeTooltip": _vm._f("numberFormat")(item.value),
          "disabled": _vm.isDisabled
        },
        on: {
          "input": function input($event) {
            return _vm.refreshTable(true);
          },
          "blur": function blur($event) {
            return _vm.handleCurrencyInput(item);
          },
          "paste": function paste($event) {
            $event.stopPropagation();
            return _vm.pasteHandler($event, 'value', asset, index);
          }
        },
        model: {
          value: item.value,
          callback: function callback($$v) {
            _vm.$set(item, "value", $$v);
          },
          expression: "item.value"
        }
      })], 1)]), _c('td', {
        staticClass: "pl-2 middle country-col"
      }, [_c('div', {
        staticClass: "cell"
      }, [_c('sygni-select', {
        class: [!(item !== null && item !== void 0 && item.country) ? 'error' : ''],
        attrs: {
          "options": _vm.countryOptions,
          "nativeTooltip": item.country,
          "allow-empty": false,
          "disabled": _vm.isDisabled
        },
        on: {
          "input": _vm.changeCountryOption
        },
        model: {
          value: item.country,
          callback: function callback($$v) {
            _vm.$set(item, "country", $$v);
          },
          expression: "item.country"
        }
      })], 1)]), (_vm$assetTypeOptionsE2 = _vm.assetTypeOptionsEnabledFor) !== null && _vm$assetTypeOptionsE2 !== void 0 && _vm$assetTypeOptionsE2.includes(asset === null || asset === void 0 ? void 0 : asset.code) ? _c('td', {
        staticClass: "middle market-col"
      }, [_c('div', {
        staticClass: "cell"
      }, [_c('sygni-select', {
        class: [!(item !== null && item !== void 0 && item.type) ? 'error' : ''],
        attrs: {
          "options": _vm.assetTypeOptions,
          "nativeTooltip": _vm.getAssetTypeLabel(item === null || item === void 0 ? void 0 : item.type),
          "allow-empty": false,
          "value": item === null || item === void 0 ? void 0 : item.type,
          "disabled": _vm.isDisabled
        },
        on: {
          "input": function input($event) {
            return _vm.changeTypeOption(item, $event);
          }
        }
      })], 1)]) : _vm._e(), _c('td', {
        staticClass: "middle market-col"
      }, [_c('div', {
        staticClass: "cell"
      }, [_c('sygni-select', {
        class: [!(item !== null && item !== void 0 && item.market) ? 'error' : ''],
        attrs: {
          "options": _vm.marketOptions,
          "nativeTooltip": _vm.getAssetMarketLabel(item === null || item === void 0 ? void 0 : item.market),
          "allow-empty": false,
          "disabled": _vm.isDisabled
        },
        on: {
          "input": function input($event) {
            return _vm.changeMarketOption(item);
          }
        },
        model: {
          value: item.market,
          callback: function callback($$v) {
            _vm.$set(item, "market", $$v);
          },
          expression: "item.market"
        }
      })], 1)]), _c('td', {
        staticClass: "middle"
      }, [_c('div', {
        staticClass: "cell"
      }, [_c('sygni-input', {
        attrs: {
          "nativeTooltip": item.isiiCode,
          "errorType": "inline",
          "input-length": "12",
          "errorMessage": "ISIN code must be 12 characters",
          "checkLength": true,
          "disabled": _vm.isIsiiCodeDisabled(item) || _vm.isDisabled
        },
        on: {
          "blur": function blur($event) {
            return _vm.handleInput($event, item);
          }
        },
        model: {
          value: item.isiiCode,
          callback: function callback($$v) {
            _vm.$set(item, "isiiCode", $$v);
          },
          expression: "item.isiiCode"
        }
      })], 1)]), _c('td', {
        staticClass: "middle"
      }, [_c('div', {
        staticClass: "cell"
      }, [_c('div', {
        staticClass: "details-box__option-btns"
      }, [_c('div', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          value: '',
          expression: "''",
          modifiers: {
            "hover": true
          }
        }],
        class: ['details-box__option-btn mr-2', (asset === null || asset === void 0 ? void 0 : (_asset$details = asset.details) === null || _asset$details === void 0 ? void 0 : _asset$details.length) == 1 || _vm.isDisabled ? 'disabled' : ''],
        on: {
          "click": function click($event) {
            return _vm.removeDetail(asset, item.id);
          }
        }
      }, [_c('img', {
        attrs: {
          "src": _vm._f("getIcon")('DELETE'),
          "alt": "Usuń"
        }
      })]), _c('div', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          value: '',
          expression: "''",
          modifiers: {
            "hover": true
          }
        }],
        class: ['details-box__option-btn', _vm.isDisabled ? 'disabled' : ''],
        on: {
          "click": function click($event) {
            return _vm.addNewDetail(asset, index);
          }
        }
      }, [_c('span', [_vm._v("+")])])])])])]);
    }), 0)]), _c('div', {
      key: "detail-".concat(_vm.reRenderTotals),
      staticClass: "details-box__option-total"
    }, [_c('p', {
      staticClass: "label"
    }, [_vm._v("SUMA AKTYWÓW: "), _c('span', [_vm._v(_vm._s(_vm.formatNumber(_vm.detailsAmount(asset)) ? _vm.formatNumber(_vm.detailsAmount(asset)) : '0,00') + " PLN")])]), _c('p', {
      staticClass: "label red"
    }, [_vm._v("POZOSTAŁO: "), _c('span', [_vm._v(_vm._s(_vm.formatNumber(_vm.math.number(_vm.math.subtract(_vm.math.bignumber(_vm.detailsTotal(asset)), _vm.math.bignumber(_vm.detailsAmount(asset))))) ? _vm.formatNumber(_vm.math.number(_vm.math.subtract(_vm.math.bignumber(_vm.detailsTotal(asset)), _vm.math.bignumber(_vm.detailsAmount(asset))))) : '0,00'))])])])])])])]);
  })], 2) : _vm._e(), !_vm.isDetailsStep ? _c('div', {
    staticClass: "step__content"
  }, [_vm.hasImportOption ? _c('div', {
    staticClass: "step__import"
  }, [_c('file-uploader', {
    attrs: {
      "supported-file-formats": _vm.supportedFileFormats,
      "isDisabled": _vm.isDisabled,
      "dropAreaText": "Przeciągnij i upuść Sprawozdanie Finansowe ASI w formacie XML",
      "btnText": "Zaimportuj SF XML",
      "infoText": "Format pliku: xml / max 5mb",
      "multiple": false
    },
    on: {
      "input": function input($event) {
        return _vm.uploadXmlFile($event);
      }
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "step__table assets-table"
  }, [_c('div', {
    staticClass: "step__name"
  }, [_vm._v(_vm._s("".concat((_vm$selectedASI3 = _vm.selectedASI) === null || _vm$selectedASI3 === void 0 ? void 0 : _vm$selectedASI3.name, " (").concat((_vm$selectedASI4 = _vm.selectedASI) === null || _vm$selectedASI4 === void 0 ? void 0 : _vm$selectedASI4.aifNumber, ")")))]), !_vm.isDetailsStep ? _c('div', {
    staticClass: "step__header"
  }, [_c('h1', [_vm._v("Aktywa")]), _vm._v("' "), _c('div', {
    staticClass: "step__summary text-right"
  }, [_c('p', {
    staticClass: "step__summary-label mb-2"
  }, [_vm._v("Suma aktywów: " + _vm._s(_vm._f("numberFormat")(_vm.assetsTotal, 2)) + " PLN")]), _c('p', {
    staticClass: "step__summary-label text-danger"
  }, [_vm._v("Pozostało: " + _vm._s(_vm._f("numberFormat")(_vm.liabilitiesDifference, 2)) + " PLN")])])]) : _vm._e(), _c('b-table', {
    attrs: {
      "fields": _vm.tableFields,
      "items": _vm.filteredTableAssetItems,
      "tbody-tr-class": _vm.rowClass
    },
    scopedSlots: _vm._u([{
      key: "cell(code)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell",
          on: {
            "click": function click($event) {
              return _vm.focusInput(rowData.index, '.assets-table');
            },
            "mouseenter": function mouseenter($event) {
              return _vm.showTooltip("assets-table-tooltip-".concat(rowData.index), rowData);
            },
            "mouseleave": function mouseleave($event) {
              return _vm.hideTooltip("assets-table-tooltip-".concat(rowData.index), rowData);
            }
          }
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(rowData.item.code))])])];
      }
    }, {
      key: "cell(segment)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "hoverable-element"
        }), _c('div', {
          staticClass: "cell",
          on: {
            "click": function click($event) {
              return _vm.focusInput(rowData.index, '.assets-table');
            },
            "mouseenter": function mouseenter($event) {
              return _vm.showTooltip("assets-table-tooltip-".concat(rowData.index), rowData);
            },
            "mouseleave": function mouseleave($event) {
              return _vm.hideTooltip("assets-table-tooltip-".concat(rowData.index), rowData);
            }
          }
        }, [_vm._v(" " + _vm._s(rowData.item.segment) + " ")])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(rowData) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip",
            value: {
              title: rowData.item.highlight || _vm.isDetailsStep || _vm.isDisabled ? '' : '',
              id: "assets-table-tooltip-".concat(rowData.index),
              placement: 'left'
            },
            expression: "{ title: rowData.item.highlight || isDetailsStep || isDisabled ? '' : '', id: `assets-table-tooltip-${rowData.index}`, placement: 'left' }"
          }],
          staticClass: "cell",
          on: {
            "click": function click($event) {
              return _vm.focusInput(rowData.index, '.assets-table');
            },
            "mouseenter": function mouseenter($event) {
              return _vm.showTooltip("assets-table-tooltip-".concat(rowData.index), rowData);
            },
            "mouseleave": function mouseleave($event) {
              return _vm.hideTooltip("assets-table-tooltip-".concat(rowData.index), rowData);
            }
          }
        }, [_c('div', {
          staticClass: "table__input"
        }, [_vm.isDetailsStep && !rowData.item.highlight ? _c('sygni-plain-input', {
          attrs: {
            "legacy": true,
            "disabled": true,
            "displayFormat": "number",
            "unit": "PLN",
            "placeholder": "-"
          },
          on: {
            "blur": function blur($event) {
              return _vm.resetDetails(rowData.item);
            }
          },
          model: {
            value: rowData.item.amount,
            callback: function callback($$v) {
              _vm.$set(rowData.item, "amount", $$v);
            },
            expression: "rowData.item.amount"
          }
        }) : _vm._e(), _vm.isDetailsStep && rowData.item.highlight ? _c('sygni-plain-input', {
          attrs: {
            "legacy": true,
            "disabled": true,
            "displayFormat": "number",
            "unit": "PLN",
            "value": _vm.detailsTotal(rowData.item),
            "placeholder": "-"
          },
          on: {
            "blur": function blur($event) {
              return _vm.resetDetails(rowData.item);
            }
          }
        }) : _vm._e(), !_vm.isDetailsStep && !rowData.item.highlight ? _c('sygni-plain-input', {
          attrs: {
            "legacy": true,
            "disabled": rowData.item.highlight || _vm.isDisabled,
            "displayFormat": "number",
            "unit": "PLN",
            "placeholder": "-"
          },
          on: {
            "blur": function blur($event) {
              return _vm.resetDetails(rowData.item);
            }
          },
          model: {
            value: rowData.item.amount,
            callback: function callback($$v) {
              _vm.$set(rowData.item, "amount", $$v);
            },
            expression: "rowData.item.amount"
          }
        }) : _vm._e(), !_vm.isDetailsStep && rowData.item.highlight ? _c('sygni-plain-input', {
          attrs: {
            "legacy": true,
            "disabled": rowData.item.highlight || _vm.isDisabled,
            "displayFormat": "number",
            "unit": "PLN",
            "value": _vm.detailsTotal(rowData.item),
            "placeholder": "-"
          },
          on: {
            "blur": function blur($event) {
              return _vm.resetDetails(rowData.item);
            }
          }
        }) : _vm._e()], 1)])];
      }
    }, {
      key: "row-details",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table-details"
        }, [_c('p', {
          staticClass: "table-details__header"
        }, [_vm._v("Szczegóły")]), _c('table', {
          staticClass: "table-details__table table"
        }, [_c('thead', [_c('tr', [_c('td', [_vm._v("Nazwa aktywów")]), _c('td', [_vm._v("Wartość")]), _c('td', [_vm._v("Rynek")]), _c('td', [_vm._v("Kod ISIN")])])]), _c('tbody', _vm._l(rowData.item.details, function (detail, index) {
          var _rowData$item$details;

          return _c('tr', {
            key: "".concat(detail.id, "-").concat(_vm.reRenderDetails, "-legacy")
          }, [_c('td', [_c('sygni-input', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: {
                title: 'Możesz użyć inteligentnego wklejania danych. Skopiuj nazwy i wartości z arkusza kalkulacyjnego i wklej je tutaj.',
                interactive: false,
                placement: 'bottom',
                boundary: 'viewport'
              },
              expression: "{ title: 'Możesz użyć inteligentnego wklejania danych. Skopiuj nazwy i wartości z arkusza kalkulacyjnego i wklej je tutaj.', interactive: false, placement: 'bottom', boundary: 'viewport' }",
              modifiers: {
                "hover": true
              }
            }],
            attrs: {
              "disabled": _vm.isDisabled
            },
            on: {
              "paste": function paste($event) {
                $event.preventDefault();
                $event.stopPropagation();
                return _vm.pasteHandler($event, 'name', rowData.item, index);
              }
            },
            model: {
              value: detail.name,
              callback: function callback($$v) {
                _vm.$set(detail, "name", $$v);
              },
              expression: "detail.name"
            }
          })], 1), _c('td', [_c('sygni-input', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: {
                title: 'Możesz użyć inteligentnego wklejania danych. Skopiuj nazwy i wartości z arkusza kalkulacyjnego i wklej je tutaj.',
                interactive: false,
                placement: 'bottom',
                boundary: 'viewport'
              },
              expression: "{ title: 'Możesz użyć inteligentnego wklejania danych. Skopiuj nazwy i wartości z arkusza kalkulacyjnego i wklej je tutaj.', interactive: false, placement: 'bottom', boundary: 'viewport' }",
              modifiers: {
                "hover": true
              }
            }],
            attrs: {
              "displayFormat": "number",
              "unit": "PLN",
              "disabled": _vm.isDisabled
            },
            on: {
              "input": function input($event) {
                return _vm.refreshTable();
              },
              "blur": function blur($event) {
                return _vm.handleCurrencyInput(detail);
              },
              "paste": function paste($event) {
                $event.preventDefault();
                $event.stopPropagation();
                return _vm.pasteHandler($event, 'value', rowData.item, index);
              }
            },
            model: {
              value: detail.value,
              callback: function callback($$v) {
                _vm.$set(detail, "value", $$v);
              },
              expression: "detail.value"
            }
          })], 1), _c('td', [_c('sygni-select', {
            attrs: {
              "options": _vm.marketOptions,
              "disabled": _vm.isDisabled
            },
            on: {
              "input": function input($event) {
                return _vm.changeMarketOption(detail);
              }
            },
            model: {
              value: detail.market,
              callback: function callback($$v) {
                _vm.$set(detail, "market", $$v);
              },
              expression: "detail.market"
            }
          })], 1), _c('td', [_c('div', {
            staticClass: "table-details__table-flex-row"
          }, [_c('sygni-input', {
            attrs: {
              "errorType": "inline",
              "input-length": "12",
              "errorMessage": "ISIN code must be 12 characters",
              "disabled": _vm.isIsiiCodeDisabled(detail) || _vm.isDisabled
            },
            on: {
              "blur": function blur($event) {
                return _vm.handleInput($event, detail);
              }
            },
            model: {
              value: detail.isiiCode,
              callback: function callback($$v) {
                _vm.$set(detail, "isiiCode", $$v);
              },
              expression: "detail.isiiCode"
            }
          }), _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: '',
              expression: "''",
              modifiers: {
                "hover": true
              }
            }],
            class: ['table__icon', ((_rowData$item$details = rowData.item.details) === null || _rowData$item$details === void 0 ? void 0 : _rowData$item$details.length) == 1 || _vm.isDisabled ? 'disabled' : ''],
            on: {
              "click": function click($event) {
                return _vm.removeDetail(rowData.item, detail.id);
              }
            }
          }, [_vm._v("-")])], 1)])]);
        }), 0)]), _c('div', {
          staticClass: "table-details__row"
        }, [_c('div', {
          staticClass: "table-details__total hidden"
        }, [_c('p', [_vm._v("Suma aktywów: "), _c('span', [_vm._v(_vm._s(_vm.formatNumber(_vm.detailsAmount(rowData.item)) ? _vm.formatNumber(_vm.detailsAmount(rowData.item)) : '0,00') + " PLN")])]), _c('p', [_vm._v("Pozostało: "), _c('span', [_vm._v(_vm._s(_vm.formatNumber(_vm.math.number(_vm.math.subtract(_vm.math.bignumber(_vm.detailsTotal(rowData.item)), _vm.math.bignumber(_vm.detailsAmount(rowData.item))))) ? _vm.formatNumber(_vm.math.number(_vm.math.subtract(_vm.math.bignumber(_vm.detailsTotal(rowData.item)), _vm.math.bignumber(_vm.detailsAmount(rowData.item))))) : '0,00') + " PLN")])])]), _c('div', {
          staticClass: "add-product-container"
        }, [_c('sygni-rounded-button', {
          staticClass: "filled red",
          attrs: {
            "hoverable": true,
            "disabled": !_vm.isDetailEditingEnabled(rowData.item),
            "plus-icon": true
          },
          on: {
            "click": function click($event) {
              return _vm.addNewDetailLegacy(rowData);
            }
          }
        }, [_vm._v(" Add new detail ")])], 1), _c('div', {
          staticClass: "table-details__total"
        }, [_c('p', [_vm._v("Suma aktywów: "), _c('span', [_vm._v(_vm._s(_vm.formatNumber(_vm.detailsAmount(rowData.item)) ? _vm.formatNumber(_vm.detailsAmount(rowData.item)) : '0,00') + " PLN")])]), _c('p', [_vm._v("Pozostało: "), _c('span', [_vm._v(_vm._s(_vm.formatNumber(_vm.math.number(_vm.math.subtract(_vm.math.bignumber(_vm.detailsTotal(rowData.item)), _vm.math.bignumber(_vm.detailsAmount(rowData.item))))) ? _vm.formatNumber(_vm.math.number(_vm.math.subtract(_vm.math.bignumber(_vm.detailsTotal(rowData.item)), _vm.math.bignumber(_vm.detailsAmount(rowData.item))))) : '0,00') + " PLN")])])])])])];
      }
    }], null, false, 2172144498)
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isDetailsStep,
      expression: "!isDetailsStep"
    }],
    staticClass: "step__table liabilities-table"
  }, [_c('div', {
    staticClass: "step__header"
  }, [_c('h1', [_vm._v("Pasywa")]), _c('div', {
    staticClass: "step__summary text-right"
  }, [_c('p', {
    staticClass: "step__summary-label mb-2"
  }, [_vm._v("Suma pasywów: " + _vm._s(_vm._f("numberFormat")(_vm.liabilitiesTotal, 2)) + " PLN")]), _c('p', {
    staticClass: "step__summary-label text-danger"
  }, [_vm._v("Pozostało: " + _vm._s(_vm._f("numberFormat")(_vm.assetsDifference, 2)) + " PLN")])])]), _c('b-table', {
    attrs: {
      "fields": _vm.tableFields,
      "items": _vm.tableLiabilitiesItems,
      "tbody-tr-class": _vm.rowClass
    },
    scopedSlots: _vm._u([{
      key: "cell(code)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell",
          on: {
            "click": function click($event) {
              return _vm.focusInput(rowData.index, '.liabilities-table');
            },
            "mouseenter": function mouseenter($event) {
              return _vm.showTooltip("liabilities-table-tooltip-".concat(rowData.index), rowData);
            },
            "mouseleave": function mouseleave($event) {
              return _vm.hideTooltip("liabilities-table-tooltip-".concat(rowData.index), rowData);
            }
          }
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(rowData.item.code))])])];
      }
    }, {
      key: "cell(segment)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "hoverable-element"
        }), _c('div', {
          staticClass: "cell",
          on: {
            "click": function click($event) {
              return _vm.focusInput(rowData.index, '.liabilities-table');
            },
            "mouseenter": function mouseenter($event) {
              return _vm.showTooltip("liabilities-table-tooltip-".concat(rowData.index), rowData);
            },
            "mouseleave": function mouseleave($event) {
              return _vm.hideTooltip("liabilities-table-tooltip-".concat(rowData.index), rowData);
            }
          }
        }, [_vm._v(" " + _vm._s(rowData.item.segment) + " ")])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(rowData) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip",
            value: {
              title: rowData.item.highlight || _vm.isDisabled ? '' : '',
              id: "liabilities-table-tooltip-".concat(rowData.index),
              placement: 'left'
            },
            expression: "{ title: rowData.item.highlight || isDisabled ? '' : '', id: `liabilities-table-tooltip-${rowData.index}`, placement: 'left' }"
          }],
          staticClass: "cell",
          on: {
            "click": function click($event) {
              return _vm.focusInput(rowData.index, '.liabilities-table');
            },
            "mouseenter": function mouseenter($event) {
              return _vm.showTooltip("liabilities-table-tooltip-".concat(rowData.index), rowData);
            },
            "mouseleave": function mouseleave($event) {
              return _vm.hideTooltip("liabilities-table-tooltip-".concat(rowData.index), rowData);
            }
          }
        }, [_c('div', {
          staticClass: "table__input"
        }, [_c('sygni-plain-input', {
          attrs: {
            "disabled": rowData.item.highlight || _vm.isDisabled,
            "legacy": true,
            "display-format": "number",
            "unit": "PLN",
            "placeholder": "-"
          },
          model: {
            value: rowData.item.amount,
            callback: function callback($$v) {
              _vm.$set(rowData.item, "amount", $$v);
            },
            expression: "rowData.item.amount"
          }
        })], 1)])];
      }
    }], null, false, 4003556797)
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isDetailsStep,
      expression: "!isDetailsStep"
    }],
    staticClass: "step__table additional-table"
  }, [_vm._m(2), _c('b-table', {
    attrs: {
      "fields": _vm.tableAdditionalFields,
      "items": _vm.tableAdditionalItems,
      "tbody-tr-class": _vm.rowClass
    },
    scopedSlots: _vm._u([{
      key: "cell(code)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell",
          on: {
            "click": function click($event) {
              return _vm.focusInput(rowData.index, '.additional-table');
            },
            "mouseenter": function mouseenter($event) {
              return _vm.showTooltip("additional-table-tooltip-".concat(rowData.index), rowData);
            },
            "mouseleave": function mouseleave($event) {
              return _vm.hideTooltip("additional-table-tooltip-".concat(rowData.index), rowData);
            }
          }
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(rowData.item.code))])])];
      }
    }, {
      key: "cell(segment)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "hoverable-element"
        }), _c('div', {
          staticClass: "cell",
          on: {
            "click": function click($event) {
              return _vm.focusInput(rowData.index, '.additional-table');
            },
            "mouseenter": function mouseenter($event) {
              return _vm.showTooltip("additional-table-tooltip-".concat(rowData.index), rowData);
            },
            "mouseleave": function mouseleave($event) {
              return _vm.hideTooltip("additional-table-tooltip-".concat(rowData.index), rowData);
            }
          }
        }, [_vm._v(" " + _vm._s(rowData.item.segment) + " ")])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(rowData) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip",
            value: {
              title: rowData.item.highlight || _vm.isDisabled ? '' : '',
              id: "additional-table-tooltip-".concat(rowData.index),
              placement: 'left'
            },
            expression: "{ title: rowData.item.highlight || isDisabled ? '' : '', id: `additional-table-tooltip-${rowData.index}`, placement: 'left' }"
          }],
          staticClass: "cell",
          on: {
            "click": function click($event) {
              return _vm.focusInput(rowData.index, '.additional-table');
            },
            "mouseenter": function mouseenter($event) {
              return _vm.showTooltip("additional-table-tooltip-".concat(rowData.index), rowData);
            },
            "mouseleave": function mouseleave($event) {
              return _vm.hideTooltip("additional-table-tooltip-".concat(rowData.index), rowData);
            }
          }
        }, [_c('div', {
          staticClass: "table__input"
        }, [_c('sygni-plain-input', {
          attrs: {
            "disabled": rowData.item.highlight || _vm.isDisabled,
            "error": rowData.index == 0 ? false : _vm.additionalsError,
            "legacy": true,
            "display-format": "number",
            "unit": "PLN",
            "placeholder": "-"
          },
          on: {
            "input": _vm.validateAdditionals
          },
          model: {
            value: rowData.item.amount,
            callback: function callback($$v) {
              _vm.$set(rowData.item, "amount", $$v);
            },
            expression: "rowData.item.amount"
          }
        })], 1)])];
      }
    }], null, false, 2637084746)
  })], 1)]) : _vm._e()], 1)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "details-box__header-group"
  }, [_c('p', {
    staticClass: "label mr-4"
  }, [_vm._v("CODE")]), _c('p', {
    staticClass: "label"
  }, [_vm._v("ENTER AMOUNT")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "details-box__title title"
  }, [_vm._v("Szczegóły "), _c('span', {
    staticClass: "label"
  }, [_vm._v("(MOŻESZ WKLEIĆ TABELE Z EXCELA)")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "step__header"
  }, [_c('h1', [_vm._v("Dodatkowe informacje")])]);
}]

export { render, staticRenderFns }