



















































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniLine from '@/components/layout/SygniLine.vue';
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniInfoBox from '@/components/layout/SygniInfoBox.vue';
import moment from 'moment';
import { Prop, Watch } from "vue-property-decorator";
import { mapState } from 'vuex';
import { hasCorrectAlready } from '@/shared/validators';

Component.registerHooks(['validations'])
@Component({
  components: { SygniDatePicker, SygniRadio, SygniLine, SygniCheckbox, SygniInput, SygniContainerTitle, SygniInfoBox },
  computed: {
    ...mapState('regReporting', {
      newReportFormData: (state: any) => state.newReportFormData,
      outputReportData: (state: any) => state.outputReportData,
      loadLastCompletedReport: (state: any) => state.loadLastCompletedReport,
    })
  }
})
export default class ReportStep1 extends Vue {
  @Prop() reportId: string;
  @Prop() reportStatus: string;
  @Prop() isDisabled: boolean;
  @Prop() reports: any;
  @Prop() groupedReports: any;
  @Prop() lastCompletedReport: any;

  loadLastCompletedReport!: boolean;
  newReportFormData!: any;
  outputReportData!: any;

  get isLastReportDataLoadingDisabled() {
    return this.lastCompletedReport ? false : true  
  }

  get currentReport() {
    let reports: any[] = []

    this.reports.forEach((groupedReports: any[]) => {
      groupedReports.forEach((report: any) => reports.push(report))
    })

    return this.reportId ? reports?.find((el: any) => el.id === this.reportId) : null
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get canUseAnyReportingDate() {
    return !!(this.activeUserData?.role?.regulatoryReporting === 'admin')
  }

  handleLoadLastCompletedReport(value: any) {
    this.$store.commit('regReporting/setLoadLastCompletedReport', value)
  }

  disabledDate(date: Date) {
    if (this.canUseAnyReportingDate) {
      return false
    }

    if (moment(date).year() >= moment().year()) {
      return true
    }

    if(this.registrationDate) {
      return this.registrationDate.year() <= moment(date).year() ? false : true;
    }

    return false;
  }

  setInitValues() {
    const defaultYear = moment().subtract(1, 'year').format('YYYY');
    this.$store.commit('regReporting/setReportYear', defaultYear);
  }

  setFirstReportOption(isFirstReport: boolean) {
    this.$store.commit('regReporting/setReportType', isFirstReport);
    if (!isFirstReport) {
      this.setActualCorrectNumber()
    }
  }

  setActualCorrectNumber() {
    const lastCorrect: any = this.groupedReports[this.newReportFormData?.reportYear?.value]?.sort((a: any, b: any) => b?.correctionNumber - a?.correctionNumber)[0]
    
    if (lastCorrect) {
      this.setCorrectionNumber(lastCorrect?.correctionNumber + 1);
      (this.$refs?.correctionInput as any)?.$el?.querySelector('input').focus()
    }
  }

  setCorrectionNumber(value: any) {
    this.$store.commit('regReporting/setCorrectionNumber', value);
  }

  updateReportYear(year?: string) {
    this.$store.commit('regReporting/setReportYear', year)
    if (!this.newReportFormData?.reportType?.isFirstReport) {
      this.setActualCorrectNumber()
    }
  }

  get registrationDate() {
    if(!this.outputReportData.regulatoryRegistrationDate) {
      return 0;
    }

    return moment(this.outputReportData.regulatoryRegistrationDate);
  }

  validateStep() {
    if(this.isDisabled) return;

    if(this.newReportFormData.currentStep === 1) {
      const invalidReportYear = !this.newReportFormData?.reportYear?.value;
      const invalidCorrectionNumber = !this.newReportFormData?.reportType?.isFirstReport && this.$v?.$invalid;
      const reportYear = moment(this.newReportFormData.reportYear.value);

      const cantGenerateReport = this.registrationDate && reportYear.year() < this.registrationDate.year();
  
      if (invalidReportYear || invalidCorrectionNumber || cantGenerateReport) {
        this.$emit('setNextStepBtnDisabled', true);
      } else {
        this.$emit('setNextStepBtnDisabled', false);
      }
    }
  }

  get reportCorrects() {
    let corrects: any[] = this.groupedReports[this.newReportFormData?.reportYear?.value]

    if (this.reportId) {
      corrects = corrects?.filter((report: any) => report?.id !== this.reportId)
    }

    return corrects
  }

  mounted() {
    if(this.isDisabled) return;
    
    this.setInitValues();
    this.validateStep();
  }

  get validationsObject() {
    if (!this.newReportFormData?.reportType?.isFirstReport) {
      return {
        newReportFormData: {
          reportType: {
            value: {
              hasCorrectAlready: hasCorrectAlready(this.newReportFormData?.reportType?.value, this.reportCorrects),
            }
          }
        }
      }
    } else {
      return {
        newReportFormData: {
          reportType: {
            value: {}
          }
        }
      }
    }
  }

  validations() {
    return this.validationsObject
  }

  @Watch('newReportFormData.reportYear.value', { deep: true, immediate: true }) onReportYearChange() {
    this.$store.commit('regReporting/setLoadLastCompletedReport', false)
  }

  @Watch('newReportFormData.reportType.isFirstReport', { deep: true, immediate: true }) onReportTypeIsFirstChange() {
    this.$store.commit('regReporting/setLoadLastCompletedReport', false)
  }

  @Watch('newReportFormData', { deep: true }) onFormDataChange() {
   this.validateStep();
  }
}
